import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reservation-details-not-found',
  template: `
    <div class="flex lg:space-x-4 items-center p-4 flex-col lg:flex-row">
      <div class="text-center lg:text-left">
        <h1 class="font-bold text-gofeels-blue text-4xl">Woops! #404</h1>
        <p class="text-2xl font-medium text-gofeels-blue-gray">
          Propiedad no encontrada
        </p>
      </div>
      <img
        class="max-h-[600px]"
        src="assets/images/404.png"
        alt="Propiedad no encontrada"
      />
    </div>
  `,
  host: {
    class: 'block h-screen w-full flex items-center justify-center',
  },
})
export class ReservationNotFound implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
