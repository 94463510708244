import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {map, pluck} from 'rxjs/operators';
import {apiReservationDetailsToReservationDetails} from './api-reservation-details-to-reservation-details.function';
import {Observable} from 'rxjs';
import {ReservationDetails} from './reservation-details.interface';
import {PropertyService} from 'services/*';
import {GetReservationDetailsResponse} from "./get-reservation-details-response.interface";

@Injectable({ providedIn: 'root' })
export class ReservationDetailsService {

  reservationDetails: ReservationDetails;

  constructor(
    @Inject(HttpClient) private readonly http: HttpClient,
    @Inject(PropertyService) private readonly propertyService: PropertyService
  ) {}

  getReservationDetails(
    reservationDetailsToken: string
  ): Observable<ReservationDetails> {
    return this.http
      .get<GetReservationDetailsResponse>(
        `${environment.url_api}/detalles-reservas/${reservationDetailsToken}`, {
          params: {
            includes: 'propiedad.insignias,propiedad.redesSociales,propiedad.recaptcha'
          }
        }
      )
      .pipe(pluck('data'), map(apiReservationDetailsToReservationDetails));
  }
}
