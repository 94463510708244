import { Inject, Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Loader } from '../interfaces';
import { SWAL } from '../tokens';

@Injectable({ providedIn: 'root' })
export class SwalLoaderService implements Loader {
  constructor(@Inject(SWAL) private readonly swal: typeof Swal) {}
  show() {
    this.swal.fire({
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => this.swal.showLoading(null),
    });
  }
  hide() {
    this.swal.close();
  }
}
