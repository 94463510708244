import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReservationNotFound } from './reservation-not-found.component';

const routes: Routes = [
  {
    path: 'charges',
    loadChildren: () =>
      import('./charges/charges.module').then((m) => m.ChargesModule),
    // canActivate: [PropertyGuard, ReservationQueryParamsGuard],
  },
  { path: 'reservation-details-not-found', component: ReservationNotFound },
  { path: '', redirectTo: 'charges', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      paramsInheritanceStrategy: 'always',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
