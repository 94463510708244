import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';
import {
  AlertDialog,
  AlertDialogOptions,
} from '../interfaces/alert-dialog.interface';
import { SWAL } from '../tokens';

@Injectable({ providedIn: 'root' })
export class SwalAlertDialogService implements AlertDialog {
  constructor(@Inject(SWAL) private readonly swal: typeof Swal) {}

  private alertDialogOptionsToSwalOptions(
    options: AlertDialogOptions
  ): SweetAlertOptions {
    const {
      buttonColor = 'var(--color-accent-500)',
      buttonText = 'Ok',
      iconClass,
      ...rest
    } = options;
    return {
      ...rest,
      confirmButtonColor: buttonColor,
      confirmButtonText: buttonText,
      customClass: {
        icon: iconClass,
      },
    };
  }

  private fire(
    icon: SweetAlertIcon,
    options: AlertDialogOptions
  ): Observable<boolean> {
    return from(
      this.swal.fire({
        icon,
        ...this.alertDialogOptionsToSwalOptions(options),
      })
    ).pipe(pluck('value'), map(Boolean));
  }

  success(options: AlertDialogOptions): Observable<boolean> {
    return this.fire('success', options);
  }

  error(options: AlertDialogOptions): Observable<boolean> {
    return this.fire('error', {
      ...options,
      iconClass: '!text-gofeels-error !border-gofeels-error',
    });
  }

  warning(options: AlertDialogOptions): Observable<boolean> {
    return this.fire('warning', options);
  }

  info(options: AlertDialogOptions): Observable<boolean> {
    return this.fire('info', options);
  }
}
