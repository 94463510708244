import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgDompurifyModule } from '@tinkoff/ng-dompurify';
import { RouterModule } from '@angular/router';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

import { MaterialModule } from './material/material.module';
import {
  FooterComponent,
  LocationComponent,
  PropertyPoliciesComponent,
  ToolbarComponent,
} from './components';

import {
  NumbersOnlyDirective,
  YoutubePlayerLoaderDirective,
} from './directives';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { errorTailorImports } from '@ngneat/error-tailor';

@NgModule({
  imports: [
    NgDompurifyModule,
    MaterialModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgbCarouselModule,
    YouTubePlayerModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgDompurifyModule,
    ToolbarComponent,
    FooterComponent,
    LocationComponent,
    PropertyPoliciesComponent,
    NgbCarouselModule,
    errorTailorImports,
    NumbersOnlyDirective,
    YoutubePlayerLoaderDirective,
    YouTubePlayerModule,
  ],
  declarations: [
    ToolbarComponent,
    FooterComponent,
    LocationComponent,
    PropertyPoliciesComponent,
    NumbersOnlyDirective,
    YoutubePlayerLoaderDirective,
  ],
})
export class SharedModule {}
