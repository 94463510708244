import { Component, Inject, OnInit } from '@angular/core';
import { PropertyService } from 'services/*';
import { Property } from 'src/app/services/property/property.interface';

@Component({
  selector: 'app-footer',
  template: `
    <div class="bg-accent-500 px-8 md:px-16 pb-16 pt-28">
      <div
        class="
      bg-primary-500
      p-8
      text-white
      relative
      max-w-7xl
      mx-auto
      -mt-44
      text-sm
      z-[400]
    "
      >
        <div
          class="flex flex-col md:flex-row md:space-x-16 mb-8 text-center md:text-left"
        >
          <div class="md:w-1/2 space-y-4">
            <h2 class="text-xl font-medium">Contacta con nosotros</h2>
            <p>
              Facilitarte un excelente servicio es una de nuestras prioridades.
              Por ello, ponemos a tu disposición toda la información de contacto
              necesaria, para que puedas comunicarte con nosotros.
            </p>
            <p>
              Estamos aquí para ayudarte a que experimentes una gran estadía.
            </p>
            <ul>
              <li>
                E-mail:
                <a href="mailto:{{ property.email }}" class="hover:underline">
                  {{ property.email }}
                </a>
              </li>
              <li>
                Teléfono:
                <a
                  href="tel:{{ property.firstPhoneNumber }}"
                  class="hover:underline"
                >
                  {{ property.firstPhoneNumber.trim() }}
                </a>
                <ng-container *ngIf="property.secondPhoneNumber">
                  /
                  <a
                    href="tel:{{ property.secondPhoneNumber }}"
                    class="hover:underline"
                  >
                    {{ property.secondPhoneNumber.trim() }}
                  </a>
                </ng-container>
              </li>
            </ul>
          </div>
          <div class="md:w-1/2 pt-6 space-y-4">
            <h2 class="text-xl font-medium">
              Síguenos en nuestras redes sociales
            </h2>
            <a target="_blank"> </a>
            <ul class="text-4xl flex space-x-4 justify-center md:justify-start">
              <li *ngFor="let socialNetwork of property.socialNetworks">
                <a target="_blank" [href]="socialNetwork.url">
                  <img
                    [src]="socialNetwork.iconUrl"
                    class="w-9 h-9 inline-block"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="flex flex-wrap gap-4 items-center mb-8"
          *ngIf="propertyHasBadges"
        >
          <ng-container *ngFor="let badge of property.badges">
            <img
              *ngIf="badge.type === 'image'"
              [src]="badge.content"
              class="w-auto h-32 object-cover"
            />
            <div
              *ngIf="badge.type === 'html'"
              class="w-auto max-h-[5rem] [&_*]:!max-h-[5rem] overflow-hidden"
              [innerHTML]="badge.content | dompurify"
            ></div>
          </ng-container>
        </div>
        <p class="text-center pb-8 md:pb-0">
          {{ property.name }} - Todos los derechos reservados
        </p>
        <div class="flex justify-center md:justify-end md:-mt-6">
          <p>
            Powered by
            <a href="https://gofeels.com" target="_blank">
              <img
                class="h-4 inline"
                src="assets/svg/logo-gofeels-blanco.svg"
              />
            </a>
          </p>
        </div>
      </div>
    </div>
  `,
  host: {
    class: 'block',
  },
})
export class FooterComponent {
  property: Property = this.propertyService.property;

  get propertyHasBadges(): boolean {
    return this.property.badges.length > 0;
  }

  constructor(
    @Inject(PropertyService) private readonly propertyService: PropertyService
  ) {}
}
