import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Confirmer, ConfirmerOptions } from '../interfaces';
import { SWAL } from '../tokens';

@Injectable({ providedIn: 'root' })
export class SwalConfirmerService implements Confirmer {
  constructor(@Inject(SWAL) private readonly swal: typeof Swal) {}

  fire(options: ConfirmerOptions): Observable<boolean> {
    options = {
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#d33',
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      ...options,
    };
    return from(
      this.swal.fire({
        ...options,
        icon: 'question',
        showConfirmButton: true,
        showDenyButton: true,
        focusDeny: true,
        focusConfirm: false,
      })
    ).pipe(pluck('value'), map(Boolean));
  }
}
