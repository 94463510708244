import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { PropertyService } from 'services/*';
import { Property } from 'src/app/services/property/property.interface';
import { HttpClient } from '@angular/common/http';
import * as L from 'leaflet';

@Component({
  selector: 'app-location',
  template: `
    <div class="py-16 bg-white">
      <div class="max-w-7xl mx-auto">
        <div class="text-center mb-16">
          <h2 id="ubicacion" class="text-3xl font-medium text-gray-700 mb-8">
            <span class="text-xl block text-gofeels-blue-gray">
              ¿Cómo llegar?</span
            >
            Ubicación
          </h2>
        </div>
      </div>
      <div #mapContainer class="h-[500px] -mb-16"></div>
    </div>
  `,
})
export class LocationComponent implements AfterViewInit {
  property: Property = this.propertyService.property;

  @ViewChild('mapContainer')
  private readonly mapContainer: ElementRef<HTMLDivElement>;

  constructor(
    @Inject(PropertyService) private readonly propertyService: PropertyService,
    @Inject(HttpClient) private readonly http: HttpClient
  ) {}

  ngAfterViewInit(): void {
    const map = L.map(this.mapContainer.nativeElement).setView(
      [this.property.location.lat, this.property.location.lng],
      17
    );
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(map);
    L.marker([this.property.location.lat, this.property.location.lng], {
      icon: L.icon({
        iconUrl: 'assets/images/map-marker.png',
        iconSize: [32, 32], // size of the icon
        iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
      }),
    }).addTo(map);
  }
}
