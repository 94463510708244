import {Injectable} from '@angular/core';
import {Property} from './property.interface';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  property: Property;

}
