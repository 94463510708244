import { Directive, OnInit } from '@angular/core';

let apiLoaded = false;

@Directive({
  selector: 'youtube-player',
})
export class YoutubePlayerLoaderDirective implements OnInit {
  ngOnInit(): void {
    if (!apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }
  }
}
