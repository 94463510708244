import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { PropertyService } from 'services/*';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-property-policies',
  template: `
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="text-xl text-gray-700 font-medium" id="politicas">
            Políticas de la propiedad
          </h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        class="
            border-gray-300 border
            p-8
            max-h-[500px]
            overflow-y-auto
          "
      >
        <div class="policies-text" [innerHTML]="propertyPolicies | dompurify"></div>
      </div>
    </mat-expansion-panel>
  `,
  host: {
    class: 'block',
  },
})
export class PropertyPoliciesComponent {
  iconAnimation: string = 'default';
  details: boolean = false;

  get propertyPolicies(): string {
    return this.propertyService.property.propertyPolicies;
  }

  @ViewChild(MatExpansionPanel)
  public readonly expansionPanel: MatExpansionPanel;

  constructor(
    @Inject(PropertyService) private readonly propertyService: PropertyService
  ) {}

  showPolicies() {
    this.iconAnimation =
      this.iconAnimation === 'default' ? 'rotated' : 'default';
    this.details = !this.details;
  }
}
