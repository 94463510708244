import { ReservationDetails } from './reservation-details.interface';
import {Data} from "./get-reservation-details-response.interface";

export function apiReservationDetailsToReservationDetails(
  details: Data
): ReservationDetails {
  const apiProperty = details.propiedad;
  return {
    id: details.id,
    number: details.numero_reserva,
    property: {
      name: apiProperty.nombre,
      id: apiProperty.id,
      accentColor: apiProperty.color_acentuado,
      primaryColor: apiProperty.color_primario,
      email: apiProperty.email,
      logo: apiProperty.logo ? apiProperty.logo : 'assets/images/logolight.png',
      location: apiProperty.position,
      firstPhoneNumber: apiProperty.telefono,
      secondPhoneNumber: apiProperty.telefono_secundario,
      socialNetworks: (apiProperty.redes_sociales || []).map((redSocial) => ({
        url: redSocial.pivot.url,
        iconUrl: redSocial.url_icono,
      })),
      propertyPolicies: apiProperty.politicas,
      showLocationMap: !apiProperty.ocultar_mapa_motor,
      googleTagManagerId: apiProperty.google_tag_manager_id,
      recaptchaPublicKey: apiProperty.recaptcha.public_key,
      badges: (apiProperty.insignias || []).map(({ contenido, tipo }) => ({
        content: contenido,
        type: ({ imagen: 'image', html: 'html' } as const)[tipo],
      })),
    }
  };
}
